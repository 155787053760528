import { Header } from "../Header";
import { addCheckInTimeInFormattedDate, addCheckOutTimeInFormattedDate } from "../../utils";

const Android = (props) => {
	const {
		reservation: { property_name, checkin_date, checkout_date, room_no, numeric_pin },
		qrCode,
		showOverlay
	} = props;
	const checkInDateTime = addCheckInTimeInFormattedDate(checkin_date);
	const checkOutDateTime = addCheckOutTimeInFormattedDate(checkout_date);

	return (
		<div className={`andriod-page-wrapper ${showOverlay && "blurredBg"} `}>
			<Header />
			<div className="numeric-pin-title">
				<p>Unlock room using QR code</p>
			</div>
			<div className="qr-code-wrap">
				<img src={qrCode} alt="" />
			</div>
			{numeric_pin && (
				<>
					<div className="numeric-pin-title">
						<p>Or using Numeric PIN</p>
					</div>
					<div className="numeric-pin">
						<p>{numeric_pin}#</p>
					</div>
				</>
			)}
			<div className="hotel-details-wrap">
				<div className="hotel-detail">
					<label className="title">hotel</label>
					<span className="about-hotel">{property_name}</span>
				</div>
				<div className="room-details">
					<label className="title">room number</label>
					<span className="about-hotel">{room_no}</span>
				</div>
				<div className="qr-validity">
					<label className="title">QR Code Validity</label>
					<span className="about-hotel">
						{checkInDateTime} - {checkOutDateTime}
					</span>
				</div>
			</div>
		</div>
	);
};

export default Android;
