import moment from "moment";

/** to know
 * whether app is running as standalone
 * or via web-browser
 */
export const isAppRunningAsPwa = () => {
  if (window.matchMedia("(display-mode: standalone)").matches) {
    return true;
  }
  return false;
};

/** format time */
export const formatDate = (date) => {
  const date_object = new Date(date);
  return moment(date_object).utcOffset(0).format("MM/DD/YYYY HH:mm");
};

/** get formatted date and append checkin time */
export const addCheckInTimeInFormattedDate = (checkInDate) => {
  const date = formatDate(checkInDate);
  return `${date}`;
};

/** get formatted date and append checkout time */
export const addCheckOutTimeInFormattedDate = (checkOutDate) => {
  const date = formatDate(checkOutDate);
  return `${date}`;
};

/** to know whether chrome is used as browser on iOS */
export const isChromeOnIos = () => {
  return (
    /CriOS/i.test(navigator.userAgent) &&
    /iphone|ipod|ipad/i.test(navigator.userAgent)
  );
};
