import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./styles.css";
import { Carousel } from "react-responsive-carousel";

export default class DemoCarousel extends Component {
  render() {
    return (
      <Carousel>
        <div className="slide-text" key="slide1" style={{ padding: 5, height: 65 }}>
          1. Tap on the share icon
          <br />
          2. Scroll down to click on “Add to Home Screen”
        </div>
      </Carousel>
    );
  }
}
