import React, { useEffect, useState } from "react";
import Slider from "../Slider";
import { Header } from "../Header";
import {
	isAppRunningAsPwa,
	addCheckInTimeInFormattedDate,
	addCheckOutTimeInFormattedDate,
	isChromeOnIos
} from "../../utils";

const IOS = (props) => {
	const [showA2hsBanner, setShowA2hsBanner] = useState(true); // to keep track of whether to show a2hs banner or not
	const appRuningAsPwa = isAppRunningAsPwa();
	const {
		reservation: { property_name, checkin_date, checkout_date, room_no, numeric_pin },
		qrCode,
		showOverlay
	} = props;
	const checkInDateTime = addCheckInTimeInFormattedDate(checkin_date);
	const checkOutDateTime = addCheckOutTimeInFormattedDate(checkout_date);

	/**
	 * if browser opened is
	 * Chrome, then do not show any a2hs banner
	 * otherwise show.
	 */
	useEffect(() => {
		if (isChromeOnIos()) {
			setShowA2hsBanner(false);
		} else {
			setShowA2hsBanner(true);
		}
	}, []);

	return (
		<div className={`andriod-page-wrapper ${showOverlay && "blurredBg"} `}>
			<Header />
			<div className="numeric-pin-title">
				<p>Unlock room using QR code</p>
			</div>
			<div className="qr-code-wrap">
				<img src={qrCode} alt="" />
			</div>
			{numeric_pin && (
				<>
					<div className="numeric-pin-title">
						<p>Or using Numeric PIN</p>
					</div>
					<div className="numeric-pin">
						<p>{numeric_pin}#</p>
					</div>
				</>
			)}
			<div className="hotel-details-wrap">
				<div className="hotel-detail">
					<label className="title">Hotel</label>
					<span className="about-hotel">{property_name}</span>
				</div>
				<div className="room-details">
					<label className="title">room number</label>
					{room_no}
				</div>
				<div className="qr-validity">
					<label className="title">QR Code Validity</label>
					<span className="about-hotel">
						{checkInDateTime} - {checkOutDateTime}
					</span>
				</div>
			</div>

			{!appRuningAsPwa && showA2hsBanner && (
				<div className="slider-wrapper-custom">
					<Slider />
				</div>
			)}
		</div>
	);
};

export default IOS;
