import React from "react";
import "./styles.css";

const ExpireState = () => {
  return (
    <div className="expire-state-wrap">
      <div className="expire-overlay"></div>
      <span>
        Key has been expired.
        <br /> <br />
        Please contact Bright Hotel Admin at{" "}
        <a href="tel:+13239991539" className="contact-details">
          {" "}
          +1 (323) 999-1539
        </a>
      </span>
    </div>
  );
};

export default ExpireState;
