import React from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import loader from "../../loader_1.gif";
import "./styles.css";

export const Spinner = () => {
  return (
    <div>
      <div className="loader-img">
        <img src={loader} alt="loader" />
      </div>
    </div>
  );
};
